<template>
<svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 800 184.3" class="m-auto h-full" style="width: 15em;fill: #005aad; enable-background:new 0 0 800 184.3;" xml:space="preserve">

    <g>
      <path class="st0" d="M94.6,127.1c-0.7,6.2-2.5,10.9-5.4,14.2c-3,3.3-7.3,4.9-12.9,4.9c-4.2,0-7.9-1-11.2-3.1
      c-3.3-2-6.8-5.5-10.6-10.3L35,108.4v35.9c-1.4,0.3-3.6,0.6-6.4,1.1s-5.7,0.7-8.6,0.7s-5.5-0.2-7.8-0.6s-4.2-1.2-5.8-2.4
      c-1.6-1.2-2.8-2.8-3.6-4.8c-0.9-2-1.3-4.7-1.3-8V16.2c1.4-0.4,3.6-0.8,6.4-1.3s5.7-0.7,8.6-0.7s5.5,0.2,7.8,0.6s4.2,1.2,5.8,2.4
      c1.6,1.2,2.8,2.8,3.6,4.8c0.9,2,1.3,4.7,1.3,8v52l33.9-34.7c6.8,0,12.3,1.5,16.5,4.5c4.1,3,6.2,6.8,6.2,11.4c0,2-0.3,3.8-1,5.4
      s-1.7,3.3-3.1,5s-3.2,3.5-5.3,5.4c-2.2,1.9-4.7,4.1-7.6,6.6l-11.4,9.9L94.6,127.1z"/>
      <path class="st0" d="M146.2,45.7c7.1,0,13.6,0.7,19.4,2.2c5.8,1.4,10.8,3.6,15,6.6c4.1,3,7.3,6.7,9.6,11.2
      c2.2,4.5,3.3,9.9,3.3,16.1v44.1c0,3.4-1,6.2-2.9,8.4c-1.9,2.2-4.2,4-6.8,5.6c-8.5,5.1-20.6,7.7-36.1,7.7c-7,0-13.2-0.7-18.8-2
      s-10.4-3.3-14.4-5.9s-7.1-6-9.3-10.1c-2.2-4.1-3.3-8.8-3.3-14.2c0-9.1,2.7-16,8.1-20.9s13.7-7.9,25-9.1l25.8-2.8v-1.4
      c0-3.8-1.7-6.5-5-8.2c-3.3-1.6-8.2-2.5-14.5-2.5c-5,0-9.9,0.5-14.6,1.6c-4.7,1.1-9,2.4-12.8,3.9c-1.7-1.2-3.2-3-4.3-5.4
      c-1.2-2.4-1.8-5-1.8-7.6c0-3.4,0.8-6.1,2.5-8.2c1.6-2,4.2-3.8,7.6-5.2c3.8-1.4,8.3-2.5,13.5-3.2C136.7,46.1,141.6,45.7,146.2,45.7z
       M147.8,123.2c2.2,0,4.7-0.2,7.4-0.7s4.7-1.1,6-1.9v-15.8L147,106c-3.7,0.3-6.7,1.1-9.1,2.4c-2.4,1.3-3.5,3.3-3.5,5.9
      s1,4.8,3.1,6.4C139.4,122.4,142.9,123.2,147.8,123.2z"/>
      <path class="st0" d="M209.2,22.1c0-5,1.7-9.2,5-12.6c3.4-3.4,7.8-5.1,13.3-5.1s9.9,1.7,13.3,5.1c3.4,3.4,5,7.6,5,12.6
      s-1.7,9.2-5,12.6s-7.8,5.1-13.3,5.1s-9.9-1.7-13.3-5.1C210.9,31.3,209.2,27.1,209.2,22.1z M244.3,144.3c-1.4,0.3-3.6,0.6-6.4,1.1
      c-2.8,0.5-5.7,0.7-8.6,0.7c-2.9,0-5.5-0.2-7.8-0.6s-4.2-1.2-5.8-2.4c-1.6-1.2-2.8-2.8-3.6-4.8c-0.9-2-1.3-4.7-1.3-8V50.1
      c1.4-0.3,3.6-0.6,6.4-1.1c2.8-0.5,5.7-0.7,8.6-0.7c2.9,0,5.5,0.2,7.8,0.6s4.2,1.2,5.8,2.4c1.6,1.2,2.8,2.8,3.6,4.8
      c0.9,2,1.3,4.7,1.3,8V144.3z"/>
      <path class="st0" d="M276.6,120c-1.4-3.3-3-7-4.6-11c-1.6-4.1-3.3-8.7-5.1-14c-1.8-5.3-3.6-11.3-5.5-18c-1.9-6.8-3.9-14.5-6-23.3
      c1.8-1.8,4.3-3.4,7.4-4.8s6.5-2.1,10.1-2.1c4.6,0,8.4,1,11.4,2.9s5.3,5.5,6.7,10.7l15.6,54.2h0.8c1.6-4.5,3.1-9.3,4.6-14.6
      c1.5-5.3,3-10.7,4.4-16.3c1.4-5.6,2.8-11.2,4-16.7c1.2-5.6,2.3-11.1,3.3-16.5c4.7-2.5,9.9-3.7,15.6-3.7c4.6,0,8.4,1,11.4,3
      s4.5,5.4,4.5,10.2c0,3.5-0.5,7.7-1.4,12.3c-0.9,4.7-2.1,9.6-3.6,14.9s-3.3,10.7-5.3,16.3c-2,5.6-4.2,11.1-6.4,16.5
      c-2.2,5.4-4.5,10.5-6.9,15.3s-4.7,9.1-6.9,12.9c-3.7,6.4-7.1,11.7-10.2,15.8c-3.2,4.1-6.2,7.3-9.1,9.6c-2.9,2.3-5.8,3.9-8.7,4.7
      c-2.9,0.9-5.9,1.3-9.1,1.3c-5.5,0-10-1.6-13.4-4.9s-5.5-7.6-6.1-12.8c4.2-3.3,8.4-6.9,12.6-10.9s8-8.1,11.4-12.3
      c-2.2-0.7-4.6-2.2-7.2-4.6C282.4,131.3,279.6,126.7,276.6,120z"/>
      <path class="st0" d="M464.4,96.6c0,8.1-1.2,15.4-3.7,21.8s-6,11.7-10.5,16.1c-4.5,4.3-9.9,7.6-16.3,9.9c-6.3,2.2-13.3,3.4-21.1,3.4
      c-7.8,0-14.8-1.2-21.1-3.5c-6.3-2.4-11.7-5.7-16.3-10.1c-4.5-4.4-8-9.8-10.5-16.1c-2.5-6.3-3.7-13.4-3.7-21.3
      c0-7.7,1.2-14.8,3.7-21.1c2.5-6.3,6-11.7,10.5-16.1s10-7.8,16.3-10.1C398,47.1,405,46,412.8,46c7.7,0,14.8,1.2,21.1,3.6
      c6.3,2.4,11.7,5.8,16.3,10.2c4.5,4.4,8,9.8,10.5,16.1C463.1,82,464.4,89,464.4,96.6z M395.4,96.6c0,8,1.5,14.2,4.6,18.4
      c3.1,4.3,7.4,6.4,12.9,6.4s9.8-2.2,12.7-6.5c3-4.3,4.4-10.4,4.4-18.3s-1.5-14-4.5-18.2c-3-4.3-7.3-6.4-12.8-6.4s-9.8,2.1-12.8,6.4
      C396.9,82.6,395.4,88.7,395.4,96.6z"/>
      <path class="st0" d="M520.7,45.7c7.9,0,15.2,1,22,3.1c6.8,2,12.6,5.2,17.4,9.4c4.9,4.2,8.7,9.5,11.4,15.9c2.8,6.4,4.1,14,4.1,22.8
      c0,8.4-1.2,15.8-3.5,22.1c-2.4,6.3-5.7,11.6-10.1,15.9c-4.3,4.3-9.6,7.5-15.7,9.6c-6.1,2.1-12.9,3.2-20.4,3.2
      c-5.6,0-10.9-0.9-15.8-2.6v32.7c-1.3,0.4-3.4,0.8-6.3,1.3s-5.8,0.7-8.9,0.7c-2.9,0-5.5-0.2-7.8-0.6s-4.2-1.2-5.8-2.4
      c-1.6-1.2-2.8-2.8-3.5-4.8c-0.8-2-1.2-4.7-1.2-8V69.4c0-3.5,0.8-6.4,2.3-8.7c1.5-2.2,3.6-4.3,6.2-6.1c4.1-2.6,9.1-4.7,15.2-6.3
      C506.5,46.5,513.2,45.7,520.7,45.7z M521.1,121c13.5,0,20.3-8.1,20.3-24.2c0-8.4-1.7-14.6-5-18.7s-8.2-6.1-14.5-6.1
      c-2.5,0-4.7,0.3-6.7,0.9s-3.7,1.3-5.1,2.1v43.3c1.6,0.8,3.3,1.4,5.1,2C517,120.7,519,121,521.1,121z"/>
      <path class="st0" d="M687.2,96.6c0,8.1-1.3,15.4-3.7,21.8c-2.5,6.4-6,11.7-10.5,16.1c-4.5,4.3-10,7.6-16.3,9.9
      c-6.3,2.2-13.3,3.4-21.1,3.4s-14.8-1.2-21.1-3.5c-6.3-2.4-11.7-5.7-16.3-10.1c-4.5-4.4-8-9.8-10.5-16.1c-2.5-6.3-3.7-13.4-3.7-21.3
      c0-7.7,1.2-14.8,3.7-21.1c2.5-6.3,6-11.7,10.5-16.1s9.9-7.8,16.3-10.1c6.3-2.4,13.3-3.5,21.1-3.5c7.7,0,14.8,1.2,21.1,3.6
      s11.7,5.8,16.3,10.2c4.5,4.4,8,9.8,10.5,16.1C686,82,687.2,89,687.2,96.6z M618.3,96.6c0,8,1.5,14.2,4.6,18.4
      c3.1,4.3,7.4,6.4,12.9,6.4s9.8-2.2,12.7-6.5c3-4.3,4.4-10.4,4.4-18.3s-1.5-14-4.5-18.2c-3-4.3-7.3-6.4-12.8-6.4s-9.8,2.1-12.8,6.4
      C619.8,82.6,618.3,88.7,618.3,96.6z"/>
      <path class="st0" d="M743.6,45.7c7.9,0,15.2,1,22,3.1c6.8,2,12.6,5.2,17.4,9.4c4.9,4.2,8.7,9.5,11.4,15.9c2.8,6.4,4.1,14,4.1,22.8
      c0,8.4-1.2,15.8-3.5,22.1c-2.4,6.3-5.7,11.6-10,15.9s-9.6,7.5-15.7,9.6c-6.1,2.1-12.9,3.2-20.4,3.2c-5.6,0-10.9-0.9-15.8-2.6v32.7
      c-1.3,0.4-3.4,0.8-6.3,1.3c-2.9,0.5-5.8,0.7-8.9,0.7c-2.9,0-5.5-0.2-7.8-0.6s-4.2-1.2-5.8-2.4c-1.6-1.2-2.8-2.8-3.5-4.8
      c-0.8-2-1.2-4.7-1.2-8V69.4c0-3.5,0.8-6.4,2.3-8.7c1.5-2.2,3.6-4.3,6.2-6.1c4.1-2.6,9.1-4.7,15.2-6.3
      C729.3,46.5,736.1,45.7,743.6,45.7z M744,121c13.5,0,20.3-8.1,20.3-24.2c0-8.4-1.7-14.6-5-18.7c-3.4-4.1-8.2-6.1-14.5-6.1
      c-2.5,0-4.7,0.3-6.7,0.9s-3.7,1.3-5.1,2.1v43.3c1.6,0.8,3.3,1.4,5.1,2C739.9,120.7,741.9,121,744,121z"/>
    </g>
    </svg>
</template>

<script>
export default {
  name: 'LogoSvg',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css">
  .st0{fill:#fff;}
</style>
