import axios from "axios";

const AxiosInstance = axios.create({
    baseURL: 'https://api.learngo.run/api',
    // timeout: 1000,
    headers: {
       'Content-Type': 'application/json; charset=utf-8'
    }
})


export default AxiosInstance;